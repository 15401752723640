
import api from '@/services/api'

export default {
    //Get all stakeholders
    allStakeholders(){
        return api().get('Stakeholder')
    },
    //Add New Screen
    addStakeholder(data){
        return api().post('Stakeholder',data)
    },    
    //get stakeholder by id
    getStakeholderById(id){
        return api().get('Stakeholder/'+id)
    },
    //Edit stakeholder
    editStakeholder(data){
        return api().put('Stakeholder/',data)
    },
    //Get all primary roles for dropdown 
    getPrimaryRoles(){
        return api().get('Stakeholder/GetRoles')
    },
    getAssumableRoles() {
        return api().get('Stakeholder/GetAssumableRoles')
    },
    //Delete stakeholder by id 
    deleteStakeholderById(id){
        return api().delete('Stakeholder/'+id)  
    },    
    //search stakeholders
    searchStakeholders(data){
        return api().get('Stakeholder?searchString='+data)
    },
}
 