<template>
  <div>
    <v-card>
      <v-card-title class="white--text primary">Create Burst</v-card-title>
      <v-form ref="createBurstForm" v-model="validBurst">
        <v-row class="pa-4">
          <v-col cols="12">
            <v-text-field
              outlined
              label="Name*"
              v-model="burst.name"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
             <!--<template v-slot:activator="{ on, attrs }">
                <v-combobox
                  outlined
                  v-model="burst.date"
                  range
                  label="Start/End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>--> 
              <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="burst.date"
              outlined
              range
              label="Start/End Date"
              append-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
            />
          </template>
              <v-date-picker v-model="burst.date" range no-title scrollable>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-select
              outlined
              :items="deliveryTypes"
              label="Delivery Type*"
              :rules="requiredRule"
              v-model="burst.deliveryTypeId"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>
        <!-- Track Campaign  -->
        <v-col cols="12" class="d-flex align-center my-n6 mt-n12 mx-1" style="gap: 1em">
          <h3>Track campaign</h3>
          <v-switch v-model="trackSwitch" dense />
        </v-col>
        <v-row v-if="trackSwitch" class=" pa-4" style="margin: 0 0.149em">
          <!-- Select Profile -->
          <h4 class="mb-4">Select an initial template milestone profile to apply to a burst: </h4>
            <v-combobox
                v-model="selectedProfile"
                :items="profiles"
                item-text="name"
                item-value="id"
                label="Select a profile"
                return-object
                outlined
                required
                style="cursor: pointer;"
            />
        </v-row>
        <v-col cols="12" class="text-right">
            <v-btn color="primary" @click="createBurst"> Save </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import CampaignController from "@/services/controllers/Campaign";
import IssueTask from '@/services/controllers/IssueTask'
export default {
  props: {
    campaign: {
      type: Object,
      required: true
    },
    deliveryTypes: {
      type: Array,
      required: true
    },
  },
  inject: ['updateBursts'],
  data() {
    return {
      burstDateRangePicker: false,
      burst: {},
      validBurst: false,
      invalidBurstDateDialog: false,
      warningText: null,
      requiredRule: [(v) => !!v || "Field is required"],
      // Track Campaign
      trackSwitch: false,
      selectedProfile: null,
      profiles: [],
    };
  },
  async created() {
        await IssueTask.getDProfiles(false).then((res) => {
            this.profiles = res.data
        })
    },
  methods: {
    createBurst() {
      this.$refs.createBurstForm.validate();
      if (this.validBurst) {
        CampaignController.addBurst({
          name: this.burst.name,
          campaignId: this.$route.query.cid,
          startDate:
            this.burst.date === undefined || this.burst.date === null
              ? null
              : this.burst.date[0] + "T00:00:00.000Z",
          endDate:
            this.burst.date === undefined || this.burst.date === null
              ? null
              : this.burst.date[1] + "T00:00:00.000Z",
          deliveryTypeId: this.burst.deliveryTypeId    
        })
          .then(async (res) => {
            const burstId = res.data.id
            if(this.trackSwitch) {
                await IssueTask.applyDProfiles(this.selectedProfile.id, burstId)
                .then((response) => {
                    if(response.status === 200) {
                        this.$root.$emit('snackbarSuccess', 'Profile applied to burst successfully!')
                    } else if(response.status === 201) {
                        this.$root.$emit('snackbarWarning', response.data.warning)
                    }
                })
                .catch((err) => {
                  this.$root.$emit("snackbarError", err)
                })
            }
            this.updateBursts(res.data)
            this.$emit("closeDialog")
            this.burst = {}
            this.$root.$emit("snackbarSuccess","Successfully created new burst.")
          })
          .catch((err) => {
            this.$root.$emit("snackbarError", ''+ err.response.data.message);
          });
      }
    },
  },
};
</script>