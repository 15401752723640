<template>
<div style="margin-top: 5px;">
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-chip style="margin-top:-5px;margin-left:25px;">
                <span
                    class="
                    d-inline-block 
                    text-truncate
                    "
                    style="
                    max-width: 400px;
                    font-size: 0.9rem;
                    "
                    v-bind="attrs" v-on="on"
                >Latest: {{ burst.workflowStatus ? burst.workflowStatus.as  : 'Milestones pending'}}
                </span>
            </v-chip>
        </template>
        <span>{{ burst.workflowStatus ? burst.workflowStatus.as : 'Miltestones pending' }}</span>
    </v-tooltip>
</div>
</template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    props: {
      burst: {
        type: Object,
        required: true
      }
    },
    computed: {
      
    },
    watch: {
      
    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
      
    }
  };
</script>