<template>
  <v-card>
    <v-card-title class="white--text primary">Edit Screen Date</v-card-title>
    <v-form ref="existingScreenEventForm" v-model="validExistingScreenEvent">
      <v-row class="mx-4 py-2">
        <v-col cols="12" class='my-0 py-0 mt-2'>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                v-model="selectedDate.date"
                outlined
                range
                label="Screen Start/End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-combobox>
            </template>
            <v-date-picker
              v-model="selectedDate.date"
              @change="checkIfCanSave();"
              range
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" class="text-right my-0 py-0 mb-2">
          <v-btn
            @click="editBurstScreen"
            :disabled="!dataHasChanged"
            :loading="savingBurstScreenUpdate"
            color="primary"
            class="mr-2"
          >
            Save
          </v-btn>
          <v-btn
            @click="$emit('closeEditDialog')"
            :disabled="savingBurstScreenUpdate"
            color="red"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import CampaignController from "@/services/controllers/Campaign";
import moment from "moment";

export default {
  props: ["campaignBurstScreen"],
  data() {
    return {
      dataHasChanged: false,
      savingBurstScreenUpdate: false,
      selectedTriggers:[],
      originalDate: { date: [] },
      selectedDate: { date: [] },
      requiredRule: [(v) => !!v || "Field is required"],
      existingScreenCreateEvent: {},
      startTimeMenuScreen: false,
      endTimeMenuScreen: false,
      burstDateRangePicker: null,
      RepeatIntervals: ["Specific Days", "daily"],
      screenSchedulingEditHeaders: [
        {
          text: "Event type",
          align: "start",
          sortable: true,
          value: "repeatInterval",
        },
        {
          text: "Day Of Week",
          align: "start",
          sortable: true,
          value: "dayOfWeek",
        },
        {
          text: "Start Time",
          align: "start",
          sortable: true,
          value: "startTime",
        },
        {
          text: "End Time",
          align: "start",
          sortable: true,
          value: "endTime",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      validExistingScreenEvent: false,
      deleteEventDialog: false,
    };
  },

  watch: { 
    campaignBurstScreen: function(newVal, oldVal) {
      this.selectedTriggers = newVal.triggerList
    }
  },  

  created() {
    this.setScreenDate()
  },

  destroyed() {
    this.$emit('persistentEditDialogOff')
  },
  
  methods: {
    setScreenDate() {
      this.selectedTriggers = this.campaignBurstScreen.triggerList
    
      //If screen has a start/end date, pre-populate the date picker
      if (this.campaignBurstScreen.startDate !== null) {
        this.selectedDate.date[0] = moment(this.campaignBurstScreen.startDate).format("YYYY-MM-DD")
        this.selectedDate.date[1] = moment(this.campaignBurstScreen.endDate).format("YYYY-MM-DD")

        this.originalDate.date = [...this.selectedDate.date]
      } 
      else {
        this.selectedDate.date = null
        this.originalDate.date = null
      }
    },

    async editBurstScreen() {
      this.$emit('persistentEditDialogOn')
      this.savingBurstScreenUpdate = true

      //Add scheduled event to screen that is already in burst
      this.$refs.existingScreenEventForm.validate();
      let burstObj = {};

      if (Object.entries(this.existingScreenCreateEvent).length === 0) {
        burstObj = {
          id: this.campaignBurstScreen.id,
          slotBreakSeconds: this.campaignBurstScreen.slotBreakSeconds,
          slotDurationSeconds: this.campaignBurstScreen.slotDurationSeconds,
          startDate:
            this.selectedDate.date === null
              ? null
              : this.selectedDate.date[0] + "T00:00:00.000Z",
          endDate:
            this.selectedDate.date === null
              ? null
              : this.selectedDate.date[1] + "T00:00:00.000Z",
              triggerList: this.selectedTriggers,
        };
      } 
      else {
        if (this.validExistingScreenEvent === true) {
          burstObj = {
            id: this.campaignBurstScreen.id,
            slotBreakSeconds: this.campaignBurstScreen.slotBreakSeconds,
            slotDurationSeconds: this.campaignBurstScreen.slotDurationSeconds,
            screenEvents: [
              {
                dayOfWeek:
                  this.existingScreenCreateEvent.RepeatInterval === "daily"
                    ? "Monday"
                    : this.existingScreenCreateEvent.dayOfWeek,
                repeatInterval: this.existingScreenCreateEvent.RepeatInterval === "Specific Days"? "Weekly":this.existingScreenCreateEvent.RepeatInterval ,
                startTime: this.existingScreenCreateEvent.startTime,
                endTime: this.existingScreenCreateEvent.endTime,
              },
            ],
            startDate:
              this.selectedDate.date === null
                ? null
                : this.selectedDate.date[0] + "T00:00:00.000Z",
            endDate:
              this.selectedDate.date === null
                ? null
                : this.selectedDate.date[1] + "T00:00:00.000Z",
                triggerList: this.selectedTriggers,
          };
        } else {
          this.$root.$emit("snackbarError", "Please check required fields.");
        }
      }

      await CampaignController.editBurstScreen(burstObj)
      .then(async (res) => {
        this.existingScreenCreateEvent = {}
        this.selectedTriggers = []
        this.burstObj = {}
        //this.$emit("getCampaignByIdFromChild");
        this.$emit("updateBurstScreen" , res.data)
        this.$emit("closeEditDialog")
        this.$root.$emit("snackbarSuccess","Successfully scheduled event to screen.")
      })
      .catch((err) => {
        this.$root.$emit("snackbarError", ''+ err.response.data.message);
      });

      this.savingBurstScreenUpdate = false
      this.$emit('persistentEditDialogOff')
    },

    checkIfCanSave() {
      // Check if data has changed
      const oDate = (this.originalDate.date) ? this.originalDate.date : ''
      const nDate = this.selectedDate.date

      this.dataHasChanged = (oDate[0] !== nDate[0] || oDate[1] !== nDate[1]) ? true : false
      
      // Check if data has changed boolean to make set dialogs persistence
      if(this.dataHasChanged)
        this.$emit('persistentEditDialogOn')
      else  
        this.$emit('persistentEditDialogOff')
    }
  },
};
</script>