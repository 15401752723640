<template>
        <div v-if="!!burst.workflowStatus && burst.workflowStatus.id !== 0">
        <div class="d-flex align-center justify-end my-4">
        <div v-for="item in [{name: 'Completed', color: '#499F68'}, {name: 'In progress', color: '#F464A9'}, {name: 'Pending', color: '#4AA6D5'}]">
            <v-tooltip top>
                <template v-slot:activator="{ attrs, on }">
                    <div
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex align-center text-caption mr-4"
                    >
                        <div 
                            :style="{ backgroundColor: item.color, width: '15px', height: '15px', borderRadius: '50%' }"
                            class="mx-2"
                        ></div>
                        {{ item.name }}
                    </div>
                </template>
                <span>{{ item.name }}</span>
            </v-tooltip>
        </div>
        </div>
        <!-- Epic Progress Bar display -->
        <div class="d-flex align-center" style="min-width: 100%; height: 25px; border-radius: 10px"> 
        <v-tooltip top v-for="category, index in burst.workflowStatus.categories" :key="category.id">
            <template v-slot:activator="{ on, attrs }" >
                <v-hover v-slot="{ hover }" >
                    <!-- Sub Issue of an epic display-->
                    <div 
                        v-bind="attrs"
                        v-on="on"
                        @click="openIssue(index, category, burst)"
                        class="text-center"
                        :class="burst.workflowStatus.categories.length > 1 ? 'subIssue' : 'oneIssue'"
                        :style="{
                            backgroundColor: filterColor(category.s),
                            width: `${100 / burst.workflowStatus.categories.length}%`,
                            opacity: hover ? 0.5 : 1,
                            height: '25px',
                            
                        }">
                        <span class="text-caption">{{ category.n }}</span>
                    </div>
                </v-hover>
            </template>
            <span>{{ category.n }}</span>
        </v-tooltip>
        
        </div>
        </div>
        <div class="my-2" v-else style="width: 100%;">
            <span>No profile milestone attached.</span>
        </div>
</template>

<script>
/** Components/Modals */

  export default {
    inject: ['filterColor', 'openIssue'],
    components: {
    },
    data() {
      return {
        openIssueModal: false,
      };
    },
    props: {
      burst: {
        type: Object,
        required: true
      }
    },
    computed: {
      
    },
    watch: {
      
    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
    }
  };
</script>
<style>
.subIssues {
    width: 100%;
}
.subIssue {
    cursor: pointer;
    /* add transition speed 300 */
    transition: 0.3s;
    height: 25px;
}
.subIssue:first-child,
.roundCorners  {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.subIssue:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.subIssue:not(:last-child) {
    border-right: 0.1px solid black;
}
.subIssue:hover {
    /* add transition speed 300 */
    transition: 0.3s;
    background-color: black;
}
.customSpacing:not(:first-child) {
    /* If not first item, add margin top 1 em */
    margin-top: 1.5em;
}
.oneIssue {
    border-radius: 10px;
}
</style>